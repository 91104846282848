import React, { useState } from "react";
import axios from "axios";
import { server, config } from "../../env";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [shippingDetails, setShippingDetails] = useState({});

  useEffect(() => {
    getOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, filter, limit, search]);

  const getOrders = async () => {
    await axios
      .get(server + "/api/v1/fed/read", config)
      .then((res) => {
        setOrders(res.data.docs);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.totalDocs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateOrder = async (id, status) => {
    await axios
      .patch(
        `${server}/api/v1/fed/update/${id}`,
        {
          status: status,
        },
        config
      )
      .then((res) => {
        toast.success(res.data.message);
        getOrders();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="card shadow-sm">
          <div className="card-header">
            <h4 className="card-title">Orders</h4>
            {/* <span className="text-info">
              Want notification on order completion? Say hi to our telegram bot
              <strong>
                <a
                  href="https://t.me/Shipgsbot"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary"
                >
                  @Shipgsbot
                </a>
              </strong>
            </span> */}
          </div>
          <div className="card-inner">
            <div className="card-filter px-4">
              <div className="d-md-flex justify-content-end align-items-center">
                <div className="d-md-flex justify-content-center align-items-center">
                  {/* .search */}
                  <div className="col mt-3">
                    <div className="form-group mr-3 ">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">OrderId</th>
                      <th scope="col">Status</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status Note</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order, index) => (
                      <tr key={order._id}>
                        <td>{index + 1 + (page - 1) * limit}</td>
                        <td>{order.uuid}</td>
                        <td>
                          {order.status === "Pending" ? (
                            <span className="badge badge-warning">Pending</span>
                          ) : order.status === "Completed" ? (
                            <span className="badge badge-success">
                              Completed
                            </span>
                          ) : order.status === "Awaiting User Input" ? (
                            <span className="badge badge-info">
                              Awaiting User Input
                            </span>
                          ) : order.status === "In Process" ? (
                            <span className="badge badge-info">In Process</span>
                          ) : (
                            <span className="badge badge-danger">
                              Cancelled
                            </span>
                          )}
                        </td>
                        <td>{"$" + order.price}</td>
                        <td>{order.note}</td>
                        <td>{new Date(order.createdAt).toString()}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn btn-sm btn-primary mr-2"
                              onClick={() => {
                                setShippingDetails(order);
                              }}
                              data-toggle="modal"
                              data-target="#view"
                            >
                              <em className="icon ni ni-eye"></em>
                            </button>

                            {/* buttons to accept or reject price */}
                            {order.status === "Awaiting User Input" && (
                              <>
                                <button
                                  className="btn btn-sm btn-success mr-2"
                                  onClick={() => {
                                    updateOrder(order._id, "In Process");
                                  }}
                                >
                                  Accept
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    updateOrder(order._id, "Cancelled");
                                  }}
                                >
                                  Reject
                                </button>
                              </>
                            )}

                            {order.status === "Completed" && (
                              <a
                                className="btn btn-sm btn-primary mr-2"
                                href={server + "/" + order.file}
                                download
                                target="_blank"
                              >
                                <em className="icon ni ni-download"></em>
                              </a>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <nav>
                  <ul className="pagination">
                    <li
                      className={
                        page === 1 ? "page-item disabled" : "page-item"
                      }
                    >
                      <Link
                        className="page-link"
                        to=""
                        onClick={(e) => {
                          setPage(page - 1);
                        }}
                      >
                        Prev
                      </Link>
                    </li>

                    <li className="page-item active" aria-current="page">
                      <Link className="page-link" to="">
                        {page} <span className="sr-only">(current)</span>
                      </Link>
                    </li>

                    <li
                      className={
                        page === totalPages ? "page-item disabled" : "page-item"
                      }
                    >
                      <Link
                        className="page-link"
                        to=""
                        tabIndex={-1}
                        aria-disabled={page === totalPages ? true : false}
                        onClick={(e) => {
                          setPage(page + 1);
                        }}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* view Order Modal */}
      <div
        id="view"
        className="modal fade "
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                View Order
              </h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                {/* Sender Information */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h6>Sender Information</h6>
                        <p>
                          <strong className="fw-bold text-muted">Name:</strong>{" "}
                          {shippingDetails?.from?.name}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Street:
                          </strong>{" "}
                          {shippingDetails?.from?.street}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Additional Street:
                          </strong>{" "}
                          {shippingDetails?.from?.street2}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">City:</strong>{" "}
                          {shippingDetails?.from?.city}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">State:</strong>{" "}
                          {shippingDetails?.from?.state}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            ZIP Code:
                          </strong>{" "}
                          {shippingDetails?.from?.zip}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Receiver Information */}
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h6>Receiver Information</h6>
                        <p>
                          <strong className="fw-bold text-muted">Name:</strong>{" "}
                          {shippingDetails?.to?.name}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Street:
                          </strong>{" "}
                          {shippingDetails?.to?.street}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Additional Street:
                          </strong>{" "}
                          {shippingDetails?.to?.street2}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">City:</strong>{" "}
                          {shippingDetails?.to?.city}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">State:</strong>{" "}
                          {shippingDetails?.to?.state}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            ZIP Code:
                          </strong>{" "}
                          {shippingDetails?.to?.zip}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Package Details */}
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h6>Package Details</h6>
                        <p>
                          <strong className="fw-bold text-muted">
                            Weight:
                          </strong>{" "}
                          {shippingDetails.weight}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Dimensions:
                          </strong>{" "}
                          {`${shippingDetails.length} x ${shippingDetails.width} x ${shippingDetails.height} ${shippingDetails.dimensionUnit} (L x W x H)`}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Weight Unit:
                          </strong>{" "}
                          {shippingDetails.weightUnit}
                        </p>
                        <p>
                          <strong className="fw-bold text-muted">
                            Special Notes:
                          </strong>{" "}
                          {shippingDetails.specialNotes}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Orders;
