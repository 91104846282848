import React, { useState, useEffect } from "react";
// eslint-disable-next-line
import { NavLink, Link } from "react-router-dom";

const Sidebar = (props) => {
  const [tab, setTab] = useState(window.location.href.split("/")[3] || "/");

  useEffect(() => {
    setTab(window.location.href.split("/")[3] || "/");
  }, []);

  return (
    <div
      className="nk-sidebar nk-sidebar-fixed is-light"
      ref={props.sidebarRef}
    >
      <div className="nk-sidebar-element nk-sidebar-head">
        <div className="nk-sidebar-brand">
          <NavLink to="/" className="logo-link nk-sidebar-logo">
            {/* <img className=" logo-img" /> */}
          </NavLink>
        </div>
        <div className="nk-sidebar-brand">
          <NavLink to="/" className="logo-link nk-sidebar-logo">
            {/* <img
              className=" logo-img"
              src="/assets/logo.png"
              alt="logo-small"
            /> */}
          </NavLink>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <Link
            to="#"
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
            onClick={() =>
              props.sidebarRef.current?.classList.toggle("nk-sidebar-active")
            }
          >
            <em className="icon ni ni-arrow-left" />
          </Link>
          <Link
            to="#"
            className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex"
            data-target="sidebarMenu"
            onClick={() =>
              props.sidebarRef.current?.classList.toggle("is-compact")
            }
          >
            <em className="icon ni ni-menu" />
          </Link>
        </div>
      </div>
      {/* .nk-sidebar-element */}
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar="">
            <ul className="nk-menu">
              {/* .nk-menu-item */}
              <li
                className={
                  tab === "/" || tab === ""
                    ? "nk-menu-item active"
                    : "nk-menu-item"
                }
                onClick={() => setTab("/")}
              >
                <NavLink
                  to="/"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em className="icon ni ni-activity-round-fill" />
                  </span>
                  <span className="nk-menu-text">Dashboard</span>
                </NavLink>
              </li>
              <li
                className={
                  tab === "createOrders"
                    ? "nk-menu-item active"
                    : "nk-menu-item"
                }
                onClick={() => setTab("createOrders")}
              >
                <NavLink
                  to="/createOrders"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em className="icon ni ni-cart-fill" />
                  </span>
                  <span className="nk-menu-text">Simple Order</span>
                </NavLink>
              </li>

              <li
                className={
                  tab === "quick-order" ? "nk-menu-item active" : "nk-menu-item"
                }
                onClick={() => setTab("quick-order")}
              >
                <NavLink
                  to="/quick-order"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em className="icon ni ni-cart-fill" />
                  </span>
                  <span className="nk-menu-text"> Fast Order</span>
                </NavLink>
              </li>

              <li
                className={
                  tab === "csv-orders" ? "nk-menu-item active" : "nk-menu-item"
                }
                onClick={() => setTab("csv-orders")}
              >
                <NavLink
                  to="/csv-orders"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em className="icon ni ni-file-text-fill" />
                  </span>
                  <span className="nk-menu-text">CSV</span>
                </NavLink>
              </li>
              <li
                className={
                  tab === "fedex-order" ? "nk-menu-item active" : "nk-menu-item"
                }
                onClick={() => setTab("fedex-order")}
              >
                <NavLink
                  to="/fedex-order"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em className="icon ni ni-cart-fill" />
                  </span>
                  <span className="nk-menu-text">Overnight FedEx</span>
                </NavLink>
              </li>
              <li
                className={
                  tab === "amazon-csv" ? "nk-menu-item active" : "nk-menu-item"
                }
                onClick={() => setTab("amazon-csv")}
              >
                <NavLink
                  to="/amazon-csv"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em className="icon ni ni-file-text-fill" />
                  </span>
                  <span className="nk-menu-text">Amazon CSV Generator</span>
                </NavLink>
              </li>

              <li
                className={
                  tab === "deposits" ? "nk-menu-item active" : "nk-menu-item"
                }
                onClick={() => setTab("deposits")}
              >
                <NavLink
                  to="/deposits"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em className="icon ni ni-file-text-fill" />
                  </span>
                  <span className="nk-menu-text">Deposits</span>
                </NavLink>
              </li>

              <li
                className={
                  tab === "address" ? "nk-menu-item active" : "nk-menu-item"
                }
                onClick={() => setTab("address")}
              >
                <NavLink
                  to="/address"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em className="icon ni ni-home-fill" />
                  </span>
                  <span className="nk-menu-text">Addresses</span>
                </NavLink>
              </li>

              <li
                className={
                  tab === "api" ? "nk-menu-item active" : "nk-menu-item"
                }
                onClick={() => setTab("api")}
              >
                <NavLink
                  to="/api-docs"
                  className="nk-menu-link "
                  onClick={() =>
                    props.sidebarRef.current?.classList.toggle(
                      "nk-sidebar-active"
                    )
                  }
                >
                  <span className="nk-menu-icon ">
                    <em class="icon ni ni-share-alt" />
                  </span>
                  <span className="nk-menu-text">Reseller API</span>
                </NavLink>
              </li>
            </ul>
            {/* .nk-menu */}
          </div>
          {/* .nk-sidebar-menu */}
        </div>
        {/* .nk-sidebar-content */}
      </div>
      {/* .nk-sidebar-element */}
    </div>
  );
};

export default Sidebar;
